import React from "react";
import Mesikapp from "../img/mesikapp_pose.png"
import { useTranslation } from "react-i18next";
import Modal from '@mui/material/Modal';
import CloseIcon from '../img/close.svg'



const Hero = () => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div className="container mx-auto max-w-[1156px] relative pt-[52px] px-4">

            <div className="hero-container">
                <div className="grid md:grid-cols-2">
                    <div className="relative">
                        <span className="hero-circle"></span>
                       <div className="relative text-white pr-[115px] md:pr-0">
                           <h1 className="font-neutra uppercase max-w-[300px] md:max-w-full text-[39px] lg:text-[67px] leading-none ">{t("hero_title")}</h1>

                           <div className="flex flex-col lg:flex-row my-[17px] lg:my-[25px] gap-[17px] lg:gap-[29px] lg:items-center">

                           </div>

                           <p className="font-avenir text-[20px] mb-[27px] lg:mb-[70px] max-w-[450px] lg:max-w-full lineheight-normal">{t("products_content")}</p>

                           <div className="lg:mx-[70px] lg:mb-[80px]">
                               <div className="w-[157px] h-[157px] lg:w-[306px] lg:h-[306px] -rotate-[11.45deg] flex justify-center items-center bg-strawberry-100 rounded-full">
                                   <p className="font-neutra text-white text-center text-[20px] lg:text-[40px] leading-none">{t("products_title")}</p>
                               </div>
                           </div>

                       </div>
                    </div>
                        <div className="absolute right-0 md:right-auto md:relative z-1 overflow-hidden md:overflow-visible">
                            <img className="w-full right-[-115px] md:right-auto max-w-[270px] md:max-w-[517px] h-auto relative" src={Mesikapp} alt=""/>
                        </div>


                </div>
            </div>
        </div>
    );
};

export default Hero;